import { triggers, useScrollProgression } from '@kaliber/scroll-progression'
import { animated, useSpringValue } from '@react-spring/web'

import { useTranslate } from '/machinery/I18n'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'
import { useDrawerGestures } from '/features/regionArticles/useDrawerGestures'

import { ContainerLg } from '/features/buildingBlocks/Container'
import { IssueSlider } from '/features/buildingBlocks/IssueSlider'
import { FooterHome } from '/features/pageOnly/Footer'
import { Splash } from '/features/pageOnly/Splash'
import { RegionSelector } from '/features/regionSelection/RegionSelector'
import { SearchCanonical } from '/features/regionArticles/Search'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Selector.css'
import mediaStyles from '/cssGlobal/media.css'

import iconSearch from '/images/icons/search.raw.svg'

const otherIssuesId = 'other-issues'

export function Selector({ image, issue, banks, issues }) {
  const { __ } = useTranslate()
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  const { searchDrawer } = useDrawerGestures({ dragGesturesEnabled: true })

  return (
    <div className={styles.component}>
      <Splash layoutClassName={styles.splashLayout} {...{ image }}>
        <div className={styles.container}>
          <div className={styles.selectorContainer}>
            <SearchButton
              onClick={searchDrawer.handleOpen}
              layoutClassName={styles.searchButtonLayout}
            />

            <RegionSelector scrollNudge {...{ issue, banks, otherIssuesId }} />
          </div>
        </div>
      </Splash>
      <BottomGradient />


      {Boolean(issues?.length) && (
        <section id={otherIssuesId} data-x='other-issues' className={styles.slidersContainer}>
          <ContainerLg>
            <IssueSlider
              title={__`read-other-editions`}
              initialSlide={0}
              origin={isViewportMd ? 0 : 'center'}
              slidesPerView={isViewportMd ? 3 : 1.1}
              {...{ issues }}
            />
          </ContainerLg>
        </section>
      )}

      <SearchCanonical
        onClose={searchDrawer.handleClose}
        isOpen={searchDrawer.isOpen}
        layoutClassName={styles.searchLayout}
        coverImage={image}
      />

      <FooterHome layoutClassName={styles.footerLayout} />
    </div>
  )
}

function SearchButton({ onClick, layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <button
      data-x='click-on-open-search'
      className={cx(styles.componentSearchButton, layoutClassName)}
      {...{ onClick }}
    >
      <Icon icon={iconSearch} />
      <p>{__`search`}</p>
    </button>
  )
}

function BottomGradient() {
  const elementRef = useScrollProgression({
    start: { element: triggers.bottom(), scrollParent: triggers.top() },
    end: { element: triggers.top(), scrollParent: triggers.bottom() },
    onChange(progression) {
      rotateX.start(`${Math.min(progression * 100, 85)}deg`)
      opacity.start(progression < 0.90
        ? 1 - progression
        : 0
      )
    }
  })

  const rotateX = useSpringValue('85deg', { config: { mass: 0.5, friction: 300, tension: 600, precision: 0.01, velocity: 0 } })
  const opacity = useSpringValue(0, { config: { mass: 0.2, tension: 600, friction: 50, precision: 0.01, velocity: 0 } })

  return (
    <div ref={elementRef} className={styles.componentBottomGradient}>
      <animated.div className={styles.gradient} style={{ rotateX, opacity }} />
    </div>
  )
}
